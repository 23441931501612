<template>
    <div class="onbills">
        <h1 class="onbills_title">对账单查询</h1>
        <el-form class="onbills_form" ref="query" :model="query" size="medium" label-width="80px" :rules="rules">
            <el-form-item prop="parkingLotId" label="对账单车场">
                <SuggestParking v-model="query.parkingLotId" class="width300" />
            </el-form-item>
            <el-form-item prop="valueDate" label="对账单日期">
                <el-date-picker :picker-options="pickerOptions" v-model="query.valueDate" end-placeholder="结束日期" range-separator="至"
                    start-placeholder="开始日期" type="daterange" value-format="yyyy-MM-dd" @change="changeDate">
                </el-date-picker>
            </el-form-item>
            <el-button type="primary" class="onbills_btn" @click="handleDown">下载对账单</el-button>
        </el-form>
    </div>
</template>
<script>
import SuggestParking from "@/components/SuggestParking";
// import { exportTradeOrder } from "@/api/operation";
export default {
    name: 'onbills',
    components: {
        SuggestParking,
    },
    data() {
        return {
            query: {
                parkingLotId: "",
                valueDate: [],
            },
            rules: {
                parkingLotId: [
                    { required: true, message: '请选择停车场', trigger: 'blur' },
                ],
                valueDate: [
                    { required: true, message: '请选择日期', trigger: 'change' }
                ],
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < new Date().getTime() - 24 * 3600 * 1000 * 90
                        || time.getTime() > new Date().getTime();
                },
                shortcuts: [
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
        }
    },
    methods: {
        handleDown() {
            this.$refs['query'].validate((valid) => {
                if (valid) {
                    // exportTradeOrder(this.query).then(res=>{
                    //     if(res && res.code == 30){
                    //         console.log(res.code);
                            
                    //     }
                    // })
                    // ${new Date().getTime()}
                    this.download('/operation/exportTradeOrder', {
                        ...this.query
                    }, `对账单_${this.query.startDate + "__" + this.query.endDate}.xlsx`)
                } else {
                    return false;
                }
            });
        },
        changeDate(e) {
            if (e) {
                this.query.startDate = e[0];
                this.query.endDate = e[1];
            } else {
                this.query.startDate = "";
                this.query.endDate = "";
            }
        }
    }
}
</script>
<style scoped lang='less'>
.onbills {
    padding: 20px;
}

.onbills_title {
    font-size: 25px;
    border-bottom: 1px solid #DDDDDE;
    padding-bottom: 20px;
}

.onbills_form {
    margin-top: 30px;
}

.onbills_btn {
    margin-left: 50px;
}
</style>